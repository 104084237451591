var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.jokeType
    ? _c("div", { staticClass: "joke-wrapper" }, [
        _c("p", { staticClass: "joke joke-line-1" }, [
          _vm._v(_vm._s(_vm.jokeLine1)),
        ]),
        _vm.jokeLine2
          ? _c("p", { staticClass: "joke joke-line-2" }, [
              _vm._v(_vm._s(_vm.jokeLine2)),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }